import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastType } from '@shared/types/toast-type.enum';
import { ToastData } from '@shared/models/toast-data.model';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public messageService: MessageService) {}

  public success(message: string, key: ToastType = ToastType.SUCCESS, duration?: number, data?: ToastData): void {
    return this._sendMessage(message, 'custom', key, duration, false, data);
  }

  public error(message: string, key: ToastType = ToastType.ERROR, duration?: number, data?: ToastData): void {
    return this._sendMessage(message, 'custom', key, duration, true, data);
  }

  public warning(message: string, key: ToastType = ToastType.WARNING, duration?: number, data?: ToastData): void {
    return this._sendMessage(message, 'custom', key, duration, true, data);
  }

  public info(message: string, key: ToastType = ToastType.INFO, duration?: number, data?: ToastData): void {
    return this._sendMessage(message, 'custom', key, duration, false, data);
  }

  private _sendMessage(
    message: string,
    classLabel: string,
    key?: ToastType,
    duration: number = 4000,
    sticky = false,
    data?: ToastData,
  ): void {
    this.messageService.add({
      key,
      severity: classLabel,
      detail: message,
      life: duration,
      sticky,
      data: data,
    });
  }
}
